<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入标题" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="会员等级:">
                    <el-select v-model="queryParams.ty" placeholder="请选择会员等级" clearable @change="changeQuery">
                        <el-option label="店主" :value="1"></el-option>
                        <el-option label="店员" :value="2"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <!-- <el-option label="不可用" :value="0"></el-option> -->
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付状态:">
                    <el-select v-model="queryParams.pay_state" placeholder="请选择支付状态" clearable @change="changeQuery">
                        <el-option label="未支付" :value="0"></el-option>
                        <el-option label="已支付" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list" style="margin-top: 30px;">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="商品id" prop="invitation_id" width="100" align="center"></el-table-column>
                <el-table-column label="标题" prop="title" width="300" align="center"></el-table-column>
                <el-table-column label="订单号" prop="ordersn" width="200" align="center"></el-table-column>
                <el-table-column label="支付单号" prop="pay_number" align="center"></el-table-column>
                <el-table-column label="价格" prop="price" width="120" align="center"></el-table-column>

                <el-table-column label="支付方式" prop="pay_type" width="120" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <!-- <el-tag type="info" v-if="scope.row.state === 0">不可用</el-tag> -->
                        <el-tag type="success" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" prop="pay_state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.pay_state === 0">未支付</el-tag>
                        <el-tag type="primary" v-if="scope.row.pay_state === 2">已支付</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.state === 1" type="text">
                            订单详情</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            menuList: ['功能', '邀请码订单'],
            loading: false,
            header: {},
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
                countType: '',
                num: '',
            },
            pageInfo: {},
            brandList: [],
            addVisible: false,
            title: '添加邀请码',
            rules: {
                name: [
                    { required: true, message: '商城名称不能为空', trigger: 'blur' }
                ]
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            mode: 'add',
            form: {
                countType: 1,
                num: 1,
                countType: 1,
                ty: 1,
            },
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            //邀请码列表
            request.get('/plug/invitation/order/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.stime = deal.timestamp(val.stime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        var url = this.mode == 'add' ? '/plug/invitation/add' : '/plug/invitation/edit';
                        this.form.stime = deal.timestamp(this.form.stime);
                        this.form.etime = deal.timestamp(this.form.etime);
                        if (this.mode === 'add') {
                        }
                        //处理时间
                        console.log(5555, this.form)
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/plug/invitation/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    font-size: 28px;
    line-height: 100px;
}

.avatar-uploader .avatar-uploader-icon {
    line-height: 100px;
}

.cell {
    text-align: center;
}

.el-input--suffix .el-input__inner {
    padding-right: 15px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
</style>